function generatePubSub({
  pubSub,
  user = null,
  loading = false,
  error = null,
}, profileEventName, cardType) {
  if (!pubSub) return;
  return pubSub.pub(profileEventName, {
    loyaltyName: cardType,
    user,
    loading,
    error,
  });
}

export default generatePubSub;
