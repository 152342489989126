function createPubSub() {
  /**
   * @typedef {Object} Topic
   * @property {Function[]} listeners
   * @property {any} value
   */
  /**
   * @type {{ [name: string]: Topic }}
   */
  const topics = {};

  function getOrAddTopic(topic) {
    if (!topics[topic]) {
      topics[topic] = {
        listeners: [],
        value: undefined,
      };
    }

    return topics[topic];
  }

  const pubSub = {
    pub: (topicName, value) => {
      const topic = getOrAddTopic(topicName);
      topic.value = value;
      topic.listeners.forEach(listener => listener(value));
    },
    sub: (topicName, handler) => {
      const topic = getOrAddTopic(topicName);
      topic.listeners.push(handler);

      return () => {
        const index = topic.listeners.indexOf(handler);
        topic.listeners.splice(index, 1);
      };
    },
    value: topicName => getOrAddTopic(topicName).value,
  };

  return pubSub;
}

export const getPubSubEventName = (cardType) => {
  const types = {
    'costapass': {
      profile: 'costapass_profile_widget',
      refresh: 'costapass_profile_refresh',
      signOut: 'costapass-signOut',
    },
    'default': {
      profile: 'siempreplus',
      refresh: 'siempreplus_refresh',
      signOut: 'siempreplus-signOut',
      signIn: 'siempreplus-signIn',
    },
  }
  return types[cardType] ?? types.default;
}

if (typeof window !== 'undefined' && !window.reservamosPubSub) {
  window.reservamosPubSub = createPubSub();
}
