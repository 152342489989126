import { h, Component } from 'preact';
import { IntlProvider, Text } from 'preact-i18n';
import preactLocalStorage from 'preact-localstorage';
import camelizeKeys from 'camelize-keys';
import translations from '../translations';
import SignIn from './SignIn';
import UserInfo from './UserInfo';
import getSession from '../utils/getSession';
import parseHiddenProp from '../utils/parseHiddenProp';
import mixpanelTracker from '../utils/mixpanelTracker';
import { getTheme, serializeColors } from '../utils/theme';
import apiConfig from '../../config/apiConfig';
import Cookies from 'js-cookie';
import psl from 'psl';
import generatePubSub from '../utils/generatePubSub';
import getMixpanelPrefix from '../utils/mixpanelPrefix';
import { removeURLParam, removeAllURLParams } from '../utils/urlsCleaner';
import { render as renderAsString } from 'preact-render-to-string';
import { getPubSubEventName } from '../utils/pubSub';
import ConfigContext from './context/ConfigContext';

export default class Root extends Component {
  constructor(props) {
    super(props);
    this.apiConfig = { ...this.setOrigin() };
    this.theme = { ...getTheme(props) };
    this.session = { ...getSession(this.apiConfig) };
    this.state = {
      isLoading: true, // Flag that indicates to show "Cargando" as label
      userInfo: undefined, // User information
      needThirdPartyExtraData: false, // Flag used to open modal with just additional data, after attempting signin in with social account (thirdParty)
      userInfoParam: undefined, // User info given in url params | separated variable for security purpose since userInfo is used when user has a correct signin
    };
    this.clearExtraDataModal = this.clearExtraDataModal.bind(this);
  }

  componentDidMount() {
    const {
      variant = 'link',
      startupOnMount = true,
      mixpanelPrefix = 'IAMSA',
      thirdParty,
      cardType,
    } = this.getProps();

    const {
      profile: profileEventName,
      refresh: refreshEventName,
    } = getPubSubEventName(cardType);

    if (thirdParty || cardType === 'doters') this.getUserToken();

    const pubSub = window.reservamosPubSub;

    this.mixpanelTracker = mixpanelTracker(
      variant,
      getMixpanelPrefix(mixpanelPrefix),
    );

    this.dispose = pubSub.sub(profileEventName, ({ user, loading, error }) => {
      const { needThirdPartyExtraData } = this.state;
      if (typeof loading === 'boolean') {
        this.setState({ isLoading: loading });
      }

      this.setState({ userInfo: user });
      if (window.SearchWidget) {
        window.SearchWidget.setRecentTrips(
          user ? camelizeKeys(user.trips) : [],
        );
      }

      // If an error ocurr and attempting to auth using thirdParty
      // Then remove third party auth to avoid prompting for extra data
      if (user || (error && needThirdPartyExtraData)) {
        this.setState({ needThirdPartyExtraData: false });
      }
    });

    this.refresh = pubSub.sub(refreshEventName, () => {
      const siempreplus = pubSub.value(profileEventName);
      if (siempreplus) {
        if (!siempreplus.loading) {
          siempreplus.loading = true;
          pubSub.pub(profileEventName, siempreplus);
          this.restart();
        }
      }
    });

    const siempreplus = pubSub.value(profileEventName);
    if (siempreplus) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isLoading: Boolean(siempreplus.loading),
        userInfo: siempreplus.user,
      });
      return;
    }

    if (startupOnMount === true || startupOnMount === 'true') {
      this.start();
    } else {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    const { variant } = this.props;
    if (variant === 'link-home') return;
    this.dispose();
    this.refresh();
  }

  getUserToken() {
    const { variant, cookieExpiration = 1 } = this.props;
    const { tokenName } = this.apiConfig;
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const errorCode = Number(urlParams.get('error_code'));
    const userToken = urlParams.get(tokenName);
    if (errorCode === 34 && variant !== 'link-home') {
      const userInfoParam = {
        firstName: urlParams.get('user[first_name]'),
        lastName: urlParams.get('user[last_name]'),
        email: urlParams.get('user[email]'),
        birthdate: urlParams.get('user[birthday]'),
        gender: urlParams.get('user[gender]'),
        mobile: urlParams.get('user[mobile]'),
        phone: urlParams.get('user[phone]'),
        id: urlParams.get('user[id]'),
        provider: urlParams.get('user[provider]'),
      };
      history.replaceState({}, null, removeAllURLParams(window.location.href));
      this.setState({ userInfoParam, needThirdPartyExtraData: true });
      // Remove params from the URL
    } else if (userToken) {
      this.setState({
        needThirdPartyExtraData: false,
        userInfoParam: undefined,
      });
      const domain = psl.parse(window.location.hostname).domain;
      Cookies.set(tokenName, userToken, {
        domain,
        expires: Number(cookieExpiration),
      });
      history.replaceState(
        {},
        null,
        removeURLParam(tokenName, window.location.href),
      );
    }
    if (errorCode === 33) {
      history.replaceState({}, null, removeAllURLParams(window.location.href));
      window.reservamosPubSub.pub(
        'siempreplusAlertError',
        renderAsString(
          <IntlProvider
            definition={
              translations[preactLocalStorage.get('i18nextLng', 'es-MX')]
            }
          >
            <Text id={'error.account_deleted'} />
          </IntlProvider>,
        ),
      );
    }
    if (errorCode === 35) {
      history.replaceState({}, null, removeAllURLParams(window.location.href));
      window.reservamosPubSub.pub(
        'siempreplusAlertError',
        renderAsString(
          <IntlProvider
            definition={
              translations[preactLocalStorage.get('i18nextLng', 'es-MX')]
            }
          >
            <Text id={'error.unexpected_error'} />
          </IntlProvider>,
        ),
      );
    }
    if (!Cookies.get(tokenName)) {
      window.reservamosPubSub.pub('notUserSession');
    }
  }

  clearExtraDataModal() {
    this.setState({ needThirdPartyExtraData: false, userInfoParam: undefined });
  }

  setOrigin() {
    const { origin, cardType, cookieExpiration, endpointName } = this.props;
    return apiConfig(cardType, origin, cookieExpiration, endpointName);
  }

  validateLanguage(language) {
    const types = ['es-MX', 'en-US'];
    return types.includes(language);
  }

  getProps() {
    const {
      hidden = '',
      modifierClass = '',
      variant = 'link',
      signInTitle = <Text id={'label.login_and_accumulate_points'} />,
      signUpTitle = <Text id={'label.start_process_to_accumulate_kms'} />,
      recoverTitle = <Text id={'label.recover_password'} />,
      emailSentTitle = <Text id={'label.recover_password'} />,
      extraDataTitle = <Text id={'label.confirm_extra_data'} />,
      thirdParty = true,
      facebookLogin = true,
      googleLogin = true,
      outlookLogin = true,
      insistLogin,
      recoverPassword,
      registerAccount,
      iconType = 'siempreplus',
      buttonLogo,
      loggedIn = false,
      modalLogo = false,
      headerColor = false,
      hideIcon = false,
      insideIcon = false,
      cardType = 'siempreplus',
      invitationText,
      buttonText,
      linkText,
      ctaText,
      logoutApi = 'false',
      mixpanelPrefix = 'IAMSA',
      externalRegisterUrl,
      language,
      cookieExpiration = 1,
      onSidebar,
      dotersGradientBanner,
      earnedPoints,
      captchaEnabled,
      sandbox = false,
    } = this.props;

    return {
      ...this.props,
      signInTitle,
      signUpTitle,
      recoverTitle,
      emailSentTitle,
      extraDataTitle,
      hidden,
      modifierClass,
      variant,
      thirdParty: thirdParty === '' || thirdParty === 'true',
      facebookLogin:
        facebookLogin === '' ||
        facebookLogin === 'true' ||
        facebookLogin === true,
      googleLogin:
        googleLogin === '' || googleLogin === 'true' || googleLogin === true,
      outlookLogin:
        outlookLogin === '' || outlookLogin === 'true' || outlookLogin === true,
      insistLogin: insistLogin === '' || insistLogin === 'true',
      recoverPassword: recoverPassword === '' || recoverPassword === 'true',
      registerAccount: registerAccount === '' || registerAccount === 'true',
      iconType,
      buttonLogo,
      hideIcon: hideIcon === '' || hideIcon === 'true',
      insideIcon: insideIcon === '' || insideIcon === 'true',
      headerColor: headerColor === '' || headerColor === 'true',
      modalLogo: modalLogo === '' || modalLogo === 'true',
      loggedIn: loggedIn === '' || loggedIn === 'true',
      cardType,
      invitationText,
      buttonText,
      linkText,
      ctaText,
      logoutApi,
      mixpanelPrefix: getMixpanelPrefix(mixpanelPrefix),
      externalRegisterUrl,
      language,
      cookieExpiration: Number(cookieExpiration),
      onSidebar: onSidebar === '' || onSidebar === 'true',
      dotersGradientBanner:
        dotersGradientBanner === '' || dotersGradientBanner === 'true',
      earnedPoints,
      captchaEnabled: captchaEnabled === '' || captchaEnabled === 'true',
      sandbox: sandbox === '' || sandbox === 'true',
    };
  }

  start() {
    const { cardType } = this.getProps();
    const pubSub = window.reservamosPubSub;
    const { profile: profileEventName } = getPubSubEventName(cardType);
    const { tokenName, cookieExpiration } = this.apiConfig;
    const isLoggingIn = Boolean(pubSub.value(profileEventName));
    if (isLoggingIn) return;

    generatePubSub({ pubSub, loading: true }, profileEventName, cardType);
    if (Cookies.get(tokenName)) {
      this.session
        .getProfile()
        .then(user => {
          const { token } = user;
          const { domain } = psl.parse(window.location.hostname);
          if (token)
            Cookies.set(tokenName, token, {
              domain,
              expires: cookieExpiration,
            });
          generatePubSub({ pubSub, user }, profileEventName, cardType);
        })
        .catch(error => {
          const domain = psl.parse(window.location.hostname).domain;
          Cookies.remove(tokenName, { domain });
          generatePubSub({ pubSub, error }, profileEventName, cardType);
        });
    } else {
      generatePubSub({ pubSub }, profileEventName, cardType);
    }
  }

  restart() {
    const { cardType } = this.getProps();
    const { profile: profileEventName } = getPubSubEventName(cardType);
    const { tokenName, cookieExpiration } = this.apiConfig;
    const pubSub = window.reservamosPubSub;

    if (Cookies.get(tokenName)) {
      this.session
        .getProfile()
        .then(user => {
          const { token } = user;
          const { domain } = psl.parse(window.location.hostname);
          if (token)
            Cookies.set(tokenName, token, {
              domain,
              expires: cookieExpiration,
            });
          generatePubSub({ pubSub, user }, profileEventName, cardType);
        })
        .catch(error => {
          const domain = psl.parse(window.location.hostname).domain;
          Cookies.remove(tokenName, { domain });
          generatePubSub({ pubSub, error }, profileEventName, cardType);
        });
    } else {
      generatePubSub({ pubSub }, profileEventName, cardType);
    }
  }

  updateUserInfo(userInfo, extras = {}) {
    this.setState({ userInfo, ...extras });
  }

  render() {
    const widgetProps = this.getProps();
    const {
      userInfo,
      isLoading,
      needThirdPartyExtraData,
      userInfoParam,
    } = this.state;
    const {
      hidden,
      variant,
      modifierClass,
      invitationText,
      buttonText,
      linkText,
      ctaText,
      insistLogin,
      thirdParty,
      facebookLogin,
      googleLogin,
      outlookLogin,
      iconType,
      insideIcon,
      buttonLogo,
      hideIcon,
      modalLogo,
      loggedIn,
      headerColor,
      modalIcon,
      modalDescription,
      language,
      onSidebar,
      dotersGradientBanner,
      earnedPoints,
    } = widgetProps;
    const hide = parseHiddenProp(hidden);

    const signOut = () => {
      this.setState({ userInfo: null });
    };

    if (hide.onLoading && isLoading) return null;

    if (hide.withSession && userInfo) return null;

    // Si no tiene sesion y tiene la configuracion de ocultar si no tiene sesion, retorna null
    if (hide.withoutSession && !userInfo) return null;

    const hasInsistLoginAtUrl =
      window && window.location.search.includes('insist-login');

    const shouldShowExtraModalData =
      variant !== 'link-home' && needThirdPartyExtraData;
    const shouldClearExtraModalData =
      variant !== 'link-home' && this.clearExtraDataModal;

    const languageToUse = this.validateLanguage(language)
      ? language
      : preactLocalStorage.get('i18nextLng', 'es-MX');

    return (
      <div
        className="w-siempre-plus"
        style={serializeColors(this.theme.colors)}
      >
        {userInfo ? (
          <IntlProvider definition={translations[languageToUse]}>
            <ConfigContext.Provider value={this.apiConfig}>
              <UserInfo
                variant={variant}
                user={userInfo}
                signOut={signOut}
                session={this.session}
                mixpanelTracker={this.mixpanelTracker}
                modifierClass={modifierClass}
                widgetProps={widgetProps}
              />
            </ConfigContext.Provider>
          </IntlProvider>
        ) : (
          <IntlProvider definition={translations[languageToUse]}>
            <ConfigContext.Provider value={this.apiConfig}>
              <SignIn
                variant={variant}
                session={this.session}
                mixpanelTracker={this.mixpanelTracker}
                modifierClass={modifierClass}
                invitationText={invitationText}
                buttonText={buttonText}
                linkText={linkText}
                ctaText={ctaText}
                isLoading={isLoading}
                insistLogin={insistLogin && hasInsistLoginAtUrl}
                thirdParty={thirdParty}
                loggedIn={loggedIn}
                modalLogo={modalLogo}
                headerColor={headerColor}
                facebookLogin={facebookLogin}
                googleLogin={googleLogin}
                outlookLogin={outlookLogin}
                widgetProps={widgetProps}
                iconType={iconType}
                insideIcon={insideIcon}
                buttonLogo={buttonLogo}
                hideIcon={hideIcon}
                modalIcon={modalIcon}
                modalDescription={modalDescription}
                showModalExtraData={shouldShowExtraModalData}
                user={userInfoParam}
                clearExtraDataModal={shouldClearExtraModalData}
                onSidebar={onSidebar}
                dotersGradientBanner={dotersGradientBanner}
                earnedPoints={earnedPoints}
              />
            </ConfigContext.Provider>
          </IntlProvider>
        )}
      </div>
    );
  }
}
