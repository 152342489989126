import habitat from 'preact-habitat';
import Root from './components/Root';
import './stylesheets/main.scss';

// El timeout 0 es para colocar esta funcion al final de la cola de funciones
// La promesa obtiene la instancia habitat
export default selector => new Promise(resolve => setTimeout(() => {
  const widgetHabitat = habitat(Root);

  widgetHabitat.render({
    selector,
    clean: true,
  });
  resolve(widgetHabitat);
}, 0));
