export function createSSOLoginUrl({
  ssoHost,
  ssoLoginRedirectUri,
  ssoClientSecret,
  ssoClientId,
  registerParam,
}) {
  // The funnel session id is got to use as state param in the sso
  const sessionId = window.sessionStorage.getItem('reservamos.sessionId');

  const lang = localStorage.getItem('i18nextLng') || 'es-MX';
  let url = `${ssoHost}/?clientId=${ssoClientId}`;
  url += `&clientSecret=${ssoClientSecret}`;
  url += `&language=${lang}`;
  if (sessionId) {
    url += `&state=${sessionId}`;
  }
  url += `&register=${registerParam}`;
  url += `&redirectUri=${ssoLoginRedirectUri}`;
  return url;
}

export function createSSOLogoutUrl(host, clientId, ssoLogoutRedirectUri) {
  const { hostname, protocol } = window.location;
  const baseHostname = `${protocol}//${hostname}`;
  const logoutRedirectUri =
    ssoLogoutRedirectUri || `${baseHostname}/sso/logout`;
  let url = `${host}/logout?post_logout_redirect_uri=${logoutRedirectUri}&client_id=${clientId}`;

  return url;
}
