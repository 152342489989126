const scriptId = 'google-recaptcha-v3';

/**
 * Function to generate the src for the script tag
 *
 * @param {boolean} useEnterprise - Whether to use the enterprise version or not
 * @returns
 */
const generateGoogleRecaptchaSrc = ({ useEnterprise }) => {
  const hostName = 'google.com';
  const script = useEnterprise ? 'enterprise.js' : 'api.js';

  return `https://www.${hostName}/recaptcha/${script}`;
};

/**
 * Function to remove default badge
 *
 * @returns
 */
const removeDefaultBadge = () => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode);
  }
};

/**
 * Function to clean the recaptcha_[language] script injected by the recaptcha.js
 */
const cleanGstaticRecaptchaScript = () => {
  const script = document.querySelector(
    'script[src^="https://www.gstatic.com/recaptcha/releases"]',
  );

  if (script) {
    script.remove();
  }
};

/**
 * Verify if captcha is loaded
 */
export const isReCaptchaLoaded = () => {
  const isScriptLoaded = document.querySelector(`#${scriptId}`);
  const isInstanceLoaded = window && window.reCaptchaInstance;
  return !!isScriptLoaded || !!isInstanceLoaded;
};

/**
 * Function to clean google recaptcha script
 */
export const cleanGoogleRecaptcha = () => {
  // remove badge
  removeDefaultBadge();

  // remove old config from window
  window.___grecaptcha_cfg = undefined;

  // remove instance
  window.reCaptchaInstance = undefined;

  // remove script
  const script = document.querySelector(`#${scriptId}`);
  if (script) {
    script.remove();
  }

  cleanGstaticRecaptchaScript();
};

/**
 * Function to hide the badge
 * @returns
 */
const hideBadge = () => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge) {
    nodeBadge.style.display = 'none';
  }
};

/**
 * Function to inject the google recaptcha script
 *
 * @param reCaptchaKey - The recaptcha key
 * @param onLoad - The onLoad callback
 * @param useEnterprise - Whether to use the enterprise version or not
 * @returns {Promise<void>} - A promise that resolves when the script is loaded
 */
export const injectGoogleReCaptchaScript = ({
  reCaptchaKey,
  onLoad,
  useEnterprise,
  scritProps: { async = true } = {},
}) => {
  const reCaptchaInstance = getInstance();

  // Already initialized
  if (reCaptchaInstance) {
    onLoad();
    return;
  }
  /**
   * Generate the js script
   */
  const googleRecaptchaSrc = generateGoogleRecaptchaSrc({
    useEnterprise,
  });
  const js = document.createElement('script');
  js.id = scriptId;
  js.src = `${googleRecaptchaSrc}?render=${reCaptchaKey}`;

  js.async = !!async;
  js.onload = onLoad;

  /**
   * Append it to the head
   */
  const elementToInjectScript = document.getElementsByTagName('head')[0];

  elementToInjectScript.appendChild(js);
};

/**
 * Initialize recaptcha
 */
export const initGoogleRecaptcha = ({
  reCaptchaKey,
  useEnterprise = false,
}) => {
  // Already initialized or no key
  if (!reCaptchaKey || isReCaptchaLoaded()) {
    return;
  }

  const onLoad = () => {
    if (!window || !window.grecaptcha) {
      return;
    }

    const grecaptcha = useEnterprise
      ? window.grecaptcha.enterprise
      : window.grecaptcha;

    grecaptcha.ready(() => {
      window.reCaptchaInstance = {
        reCaptcha: grecaptcha,
        clientId: reCaptchaKey,
      };
      hideBadge();
    });
  };

  // Inject the script
  injectGoogleReCaptchaScript({
    reCaptchaKey,
    onLoad,
    useEnterprise,
  });
};

const getInstance = () => {
  if (!window || !window.reCaptchaInstance) {
    return;
  }

  return window.reCaptchaInstance;
};

/**
 * Function to execute the recaptcha
 * @param action - The action to execute
 * @param callback - The callback to execute
 * @returns {Promise<string>} - A promise that resolves with the token
 */
export const executeRecaptcha = (action, callback) => {
  const reCaptchaInstance = getInstance();

  if (!reCaptchaInstance || !reCaptchaInstance.reCaptcha) {
    return;
  }

  const { reCaptcha, clientId } = reCaptchaInstance;

  reCaptcha.execute(clientId, { action }).then(callback);
};
