export default class ValidatorOptionalWithField {
  constructor(fieldDepend) {
    this.errorName = "depends";
    this.dependsOn = fieldDepend;
  }

  isValid(value) {
    const valueDepend = document.getElementsByName(this.dependsOn)[0]?.value;
    if(!value && !valueDepend) return false;
    if(value || valueDepend) return true;
  }
}
