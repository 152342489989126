import { h } from 'preact';

const LoadingDots = () => (
  <div className="w-loading-dots">
    <div />
    <div />
    <div />
  </div>
);

export default LoadingDots;
