import { h } from 'preact';
import { Text } from 'preact-i18n';
import LoadingPulse from './LoadingPulse';

const SignInLinkHome = ({
  linkText,
  onClick,
  isLoading,
  iconType,
  hideIcon,
  cardType,
  ctaText
}) => {
  const linkTextTypes = {
    doters: "text.sign_up_and_login_doters",
    default: "text.sign_up_and_login",
  }
  let linkTextToShow = linkText;

  if (!linkText) {
    linkTextToShow = <Text id={linkTextTypes[cardType] ?? linkTextTypes['default']} />
  }
  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className={`signin-link-home ${ctaText ? 'has-cta' : ''}`}>
      <button
        className="signin-link-home-button"
        type="submit"
        onClick={onClick}
      >
        {hideIcon || <i className={`badge-${iconType}`} />}
        <span className="signin-link-home-button-text">{linkTextToShow}</span>
        {ctaText && <span className="signin-cta">{ctaText}</span>}
      </button>
    </div>
  );
}
  

  export default SignInLinkHome;