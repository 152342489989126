export default (
  cardType = 'siempreplus',
  origin,
  cookieExpiration = 1,
  endpointName,
) => {
  const defaultOrigin = 'https://wl.primeraplus.com.mx';
  const originURL = origin || defaultOrigin;
  const endPointTypes = {
    doters: 'doters',
    costapass: 'costapass',
    default: 'splus',
  };
  const reCaptchaKeyTypes = {
    siempreplus: '6LcEWXAmAAAAAPX31NqTwQ_06f768bHNiRUAyQA-',
    primeraplus: '6LcEWXAmAAAAAPX31NqTwQ_06f768bHNiRUAyQA-',
    default: null,
  };

  const root = `${originURL}/api/v2/${endpointName ||
    endPointTypes[cardType] ||
    endPointTypes.default}`;

  const tokenTypes = {
    costapass: 'costapassToken',
    default: 'token',
  };
  const tokenName = tokenTypes[cardType] ?? tokenTypes.default;
  const reCaptchaKey = reCaptchaKeyTypes[cardType] ?? reCaptchaKeyTypes.default;

  return {
    origin,
    cardType,
    root,
    tokenName,
    cookieExpiration: Number(cookieExpiration),
    signInSP: 'auth/provider_login_sp',
    signIn: 'login',
    signUp: 'register',
    signOut: 'logout',
    getProfile: 'profile',
    forgotPin: 'password_reset',
    reCaptchaKey,
  };
};
