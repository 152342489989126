import { h } from 'preact';
import { Text } from 'preact-i18n';
import ButtonWrapper from './generics/ButtonWrapper';

const SignInButton = ({
  buttonText = <Text id={'button.sign_up'} />,
  invitationText = <Text id={'text.are_not_you_always_a_plus_partner'} />,
  isLoading,
  iconType,
  hideIcon,
  insideIcon,
  onClick,
}) => (
  <div className="purchase-signin">
    {insideIcon || (
      <div className="purchase-signin-wrapper">
        {hideIcon || <i className={`badge-${iconType}`} />}
        <p className="purchase-signin-title">{invitationText}</p>
      </div>
    )}

    <ButtonWrapper
      className={`${
        insideIcon ? 'purchase-signin-icon' : 'purchase-signin-button'
      }`}
      type="button"
      onClick={onClick}
      isLoading={isLoading}
    >
      {insideIcon && <i className={`badge-${iconType}`} />}
      {buttonText}
    </ButtonWrapper>
  </div>
);

export default SignInButton;
