/* eslint-disable react-hooks/rules-of-hooks */
import { h, Component } from 'preact';
import { Text, useText } from 'preact-i18n';
import Cookies from 'js-cookie';
import { Form, Validators, validateField } from '../utils/form-helpers';
import modalContent from '../constants/modalContent';
import SignInWith from './SignInWith';
import FieldWrapper from './generics/FieldWrapper';
import ButtonWrapper from './generics/ButtonWrapper';
import AlertMessage from './generics/AlertMessage';
import { removeAllURLParams } from '../utils/urlsCleaner';
import psl from 'psl';
import { getPubSubEventName } from '../utils/pubSub';
import { executeRecaptcha } from '../utils/googleRecaptcha';
import withConfig from './hoc/withContext';

const EMAIL_FIELD = 'email';
const PASSWORD_FIELD = 'password';

class SignInContent extends Component {
  constructor() {
    super();
    this.state = {
      recaptchaToken: undefined,
      error: '',
      isLoading: false,
      form: new Form({
        [PASSWORD_FIELD]: {
          value: '',
          validators: [Validators.required()],
        },
        [EMAIL_FIELD]: {
          value: '',
          validators: [Validators.required(), Validators.email()],
        },
      }),
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCaptchaVerify = this.onCaptchaVerify.bind(this);
  }

  componentDidMount() {
    const {
      config: { reCaptchaKey },
      widgetProps,
    } = this.props;
    const { form } = this.state;
    form.clearValues(this);

    const { captchaEnabled } = widgetProps;
    // Retrieve captcha token
    if (captchaEnabled && reCaptchaKey) {
      this.onCaptchaVerify();
    }
  }

  onCaptchaVerify() {
    executeRecaptcha('siempre_plus_login', recaptchaToken => {
      this.setState({ recaptchaToken });
    });
  }

  onSubmit() {
    const { form, recaptchaToken } = this.state;
    const {
      session,
      mixpanelTracker,
      widgetProps,
      config: { tokenName },
    } = this.props;
    const { cardType } = widgetProps;
    const { pub } = window.reservamosPubSub;
    const { profile: profileEventName } = getPubSubEventName(cardType);
    this.setState({
      isLoading: true,
      error: '',
    });
    mixpanelTracker.trackSignInClick(null);
    session
      .signIn(
        {
          email: form.getValue(EMAIL_FIELD),
          password: form.getValue(PASSWORD_FIELD),
          recaptchaToken,
        },
        widgetProps.cardType,
      )
      .then(user => {
        if (cardType === 'siempreplus') {
          mixpanelTracker.trackSignIn({
            [`${widgetProps.mixpanelPrefix} Card`]: user.sp_card,
            Type: `${widgetProps.mixpanelPrefix} Card`,
          });
        }

        const domain = psl.parse(window.location.hostname).domain;
        Cookies.set(tokenName, user.token, {
          domain,
          expires: widgetProps.cookieExpiration,
        });

        this.setState({ isLoading: false });
        pub(profileEventName, {
          user,
          loyaltyName: cardType,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.setState({ isLoading: false });

        // If recaptcha is enabled and token is set, we need to verify and set it again
        if (recaptchaToken) {
          this.onCaptchaVerify();
        }

        response
          .json()
          .then(({ errors: [error] }) => {
            pub(profileEventName, {
              loading: false,
              user: null,
              loyaltyName: cardType,
              error,
            });
            this.setState({ error: error.message });
          })
          .catch(error => {
            pub(profileEventName, {
              loading: false,
              user: null,
              loyaltyName: cardType,
              error,
            });
            this.setState({ error: 'unexpected_error' });
          });
      })
      .finally(() => {
        history.replaceState(
          {},
          null,
          removeAllURLParams(window.location.href),
        );
      });
  }

  render({ onChangeContent, mixpanelTracker, thirdParty }) {
    const { form, isLoading, error } = this.state;
    const { widgetProps } = this.props;
    const {
      recoverPassword,
      registerAccount,
      facebookLogin,
      googleLogin,
      outlookLogin,
      externalRegisterUrl,
    } = widgetProps;
    const hideForgotLink = Boolean(recoverPassword) === false;
    const hideRegisterLink = Boolean(registerAccount) === false;
    const { placeholder_email, placeholder_password } = useText({
      placeholder_email: 'placeholder.email',
      placeholder_password: 'placeholder.password',
    });

    const registerButtonActionProps = {
      ...(externalRegisterUrl && { href: externalRegisterUrl }),
      ...(!externalRegisterUrl && {
        onClick: () => onChangeContent(modalContent.SIGN_UP),
      }),
    };

    const signInWithCallback = type =>
      mixpanelTracker.trackSignIn({
        [`${widgetProps.mixpanelPrefix} Card`]: null,
        Type: type,
      });

    return (
      <div>
        <form onSubmit={form.onSubmit(this, this.onSubmit)}>
          <AlertMessage type="error" visible={Boolean(error)}>
            {error}
          </AlertMessage>
          <FieldWrapper
            hasError={form.fieldHasErrors(EMAIL_FIELD)}
            className="form-item"
            errorLegend="invalid_email"
          >
            <input
              type="text"
              placeholder={placeholder_email}
              name={EMAIL_FIELD}
              onInput={validateField(this, form)}
            />
          </FieldWrapper>
          <FieldWrapper
            hasError={form.fieldHasErrors(PASSWORD_FIELD)}
            className="form-item"
            errorLegend="required_field"
          >
            <input
              type="password"
              placeholder={placeholder_password}
              name={PASSWORD_FIELD}
              onInput={validateField(this, form)}
            />
          </FieldWrapper>
          {hideForgotLink ? null : (
            <div className="form-item">
              <p className="w-modal-copy forgot-link">
                {/* eslint-disable-next-line */}
                <a
                  className="w-modal-link"
                  onClick={() => onChangeContent(modalContent.FORGOT_PIN)}
                >
                  <Text id="link.did_you_forget_your_password" />
                </a>
              </p>
            </div>
          )}
          <div className="form-item">
            <ButtonWrapper
              className="w-modal-button"
              type="submit"
              isLoading={isLoading}
            >
              <Text id={'button.log_in'} />
            </ButtonWrapper>
          </div>
        </form>
        {thirdParty ? (
          <SignInWith
            facebookLogin={facebookLogin}
            googleLogin={googleLogin}
            outlookLogin={outlookLogin}
            onSelect={signInWithCallback}
          />
        ) : null}
        {hideRegisterLink
          ? null
          : [
              // eslint-disable-next-line react/jsx-key
              <div className="or">
                <hr className="bar" />
                <span>O</span>
                <hr className="bar" />
              </div>,
              // eslint-disable-next-line react/jsx-key
              <div>
                <p className="w-modal-copy account-link">
                  {/* eslint-disable-next-line */}
                  <a className="w-modal-link" {...registerButtonActionProps}>
                    <Text id="link.you_do_not_have_an_account" />
                    <span>
                      <Text id="link.sign_up" />
                    </span>
                  </a>
                </p>
              </div>,
            ]}
      </div>
    );
  }
}

export default withConfig(SignInContent);
