const defaultTheme = {
  colors: {
    primary: '#002674',
    primarystrong: '#001541',
    accent: '#CE348B',
    accentstrong: '#A72870',
    gray600: '#232323',
    gray500: '#686868',
    gray400: '#9B9B9B',
    gray300: '#C7C7C7',
    gray200: '#E6E6E6',
    gray100: '#FAFAFA',
    white: '#FFFFFF',
    success: '#66BA5B',
    successStrong: '#448c3b',
    warning: '#E8B600',
    error: '#FF040D',
    errorStrong: '#C80007',
    info: '#00ABCB',
    infoStrong: '#009AB7',
    discountLight: '#E98f26',
    discount: '#C86B11',
    discountStrong: '#A55509',
  },
};

export function getTheme(props) {
  const colors = Object.keys(props)
    .filter(key => key.endsWith('Color'))
    .reduce(
      (result, key) => ({
        ...result,
        [key.slice(0, -5)]: props[key],
      }),
      {},
    );

  return {
    colors: {
      ...defaultTheme.colors,
      ...colors,
    },
  };
}

export function serializeColors(colors) {
  return Object.entries(colors)
    .map(([key, value]) => `--${key}: ${value};`)
    .join(' ');
}
