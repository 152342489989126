const payloadTypes = {
  costapass: costapassPayload,
  default: siempreplusPayload,
}

export function signInPayloadParser(data, cardType) {
  const parser = payloadTypes[cardType] ?? payloadTypes['default'];
  return parser(data, cardType);
}

function costapassPayload(data){
  return {
    email: data.email,
    password: data.password,
  };
};


function siempreplusPayload(data){
  return {
    sp_card: data.email,
    nip: data.password,
    recaptcha_token: data.recaptchaToken,
  };
};
