import { h } from 'preact';
import { Text } from 'preact-i18n';
/**
 * type puede ser error, success, warning
 */
const AlertMessage = ({ children, type = 'error', visible = true }) =>{
  return !visible ? null : (
    <div>
      <div className={`w-alert-message w-${type}`}>
        <p className="w-alert-message-copy">
          <Text id={`${type}.${children}`}>{children}</Text>
        </p>
      </div>
    </div>
  );
}
export default AlertMessage;
