import { h } from 'preact';
import { Text } from 'preact-i18n';

const FieldWrapper = ({
  className = '',
  hasError,
  errorLegend,
  children,
  type = 'error',
}) => (
  <div className={`${className || ''}${hasError ? ' invalid' : ''}`}>
    {children}
    {hasError ? (
      <p className="error-feedback">
        <Text id={`${type}.${errorLegend}`} />
      </p>
    ) : null}
  </div>
);

export default FieldWrapper;
