import { h, Component } from 'preact';
import { Text, useText } from 'preact-i18n';
import { Form, validateField, Validators } from '../utils/form-helpers';
import {
  normalizeName,
  normalizePhone,
} from '../utils/form-helpers/normalizers';
import Cookies from 'js-cookie';
import FieldWrapper from './generics/FieldWrapper';
import modalContent from '../constants/modalContent';
import SignInWith from './SignInWith';
import ButtonWrapper from './generics/ButtonWrapper';
import AlertMessage from './generics/AlertMessage';
import { removeAllURLParams } from '../utils/urlsCleaner';
import psl from 'psl';
import { getPubSubEventName } from '../utils/pubSub';
import withConfig from './hoc/withContext';

const FIRST_NAME_FIELD = 'firstName';
const LAST_NAME_FIELD = 'lastName';
const DAY_BIRTHDAY_FIELD = 'dayBirthdate';
const MONTH_BIRTHDAY_FIELD = 'monthBirthdate';
const YEAR_BIRTHDAY_FIELD = 'yearBirthdate';
const CELL_PHONE_FIELD = 'cellPhoneNumber';
const PHONE_FIELD = 'phoneNumber';
const EMAIL_FIELD = 'email';
const EMAIL_CONFIRMATION_FIELD = 'emailConfirmation';
const GENDER_FIELD = 'gender';
const ACCEPT_FIELD = 'accept';
const ID_FIELD = 'id';
const PROVIDER_FIELD = 'provider';

const DECIMAL = 10;

class SignUpContent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateEmailConfirmation = this.validateEmailConfirmation.bind(this);
    const { privacyUrl, termsAndConditionsUrl } = props.widgetProps;

    const showCheckbox = Boolean(privacyUrl || termsAndConditionsUrl);
    const todayDate = new Date();
    const adultYear = todayDate.getFullYear() - 18;
    this.adultDate = new Date(
      `${todayDate.getMonth() + 1}/${todayDate.getDate()}/${adultYear}`,
    );

    this.state = {
      isLoading: false,
      error: '',
      form: new Form({
        [ID_FIELD]: {
          value: '',
        },
        [PROVIDER_FIELD]: {
          value: '',
        },
        [FIRST_NAME_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.maxLength(40),
            Validators.alphaNames(),
          ],
        },
        [LAST_NAME_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.maxLength(80),
            Validators.alphaNames(),
          ],
        },
        [DAY_BIRTHDAY_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.between(1, 31),
            Validators.isEqual(value => value > 0),
          ],
        },
        [MONTH_BIRTHDAY_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.between(1, 12),
            Validators.isEqual(value => value > 0),
          ],
        },
        [YEAR_BIRTHDAY_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.maxInteger(adultYear),
            Validators.isEqual(value => value > 0),
            Validators.maxLength(4),
            Validators.minLength(4),
          ],
        },
        [GENDER_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.isEqual(
              value =>
                value === 'f' ||
                value === 'm' ||
                value === 'o' ||
                value === 'na',
            ),
          ],
        },
        [CELL_PHONE_FIELD]: {
          value: '',
          validators: [
            Validators.optionalWithField(PHONE_FIELD),
            Validators.maxLength(10),
            Validators.minLength(10),
            Validators.numeric(),
          ],
        },
        [PHONE_FIELD]: {
          value: '',
          validators: [
            Validators.optionalWithField(CELL_PHONE_FIELD),
            Validators.maxLength(10),
            Validators.minLength(10),
            Validators.numeric(),
            Validators.maxLength(128),
          ],
        },
        [EMAIL_FIELD]: {
          value: '',
          validators: [
            Validators.required(),
            Validators.email(),
            Validators.maxLength(128),
          ],
        },
        [EMAIL_CONFIRMATION_FIELD]: {
          value: '',
          validators: [Validators.isEqual(() => true)],
        },
        [ACCEPT_FIELD]: {
          value: '',
          multiple: true,
          validators: [showCheckbox && Validators.minLength(1)],
        },
      }),
      phoneInvalid: false,
    };
  }

  componentDidMount() {
    const { form } = this.state;
    const { user } = this.props;
    form.clearValues(this);

    // If we receive user data from params it means we can add it to the hidden form fields
    if (user) {
      const {
        id = '',
        provider = '',
        firstName = '',
        lastName = '',
        birthdate = '',
        cellPhoneNumber = '',
        phoneNumber = '',
        email = '',
        gender = '',
      } = user;

      const [dayBirthdate, monthBirthdate, yearBirthdate] = birthdate
        ? birthdate.split('-')
        : [0, 0, 0];

      form.setValue(ID_FIELD, id);
      form.setValue(PROVIDER_FIELD, provider);
      form.setValue(FIRST_NAME_FIELD, firstName);
      form.setValue(LAST_NAME_FIELD, lastName);
      form.setValue(
        DAY_BIRTHDAY_FIELD,
        dayBirthdate ? parseInt(dayBirthdate, DECIMAL) : undefined,
      );
      form.setValue(
        MONTH_BIRTHDAY_FIELD,
        monthBirthdate ? parseInt(monthBirthdate, DECIMAL) : undefined,
      );
      form.setValue(
        YEAR_BIRTHDAY_FIELD,
        yearBirthdate ? parseInt(yearBirthdate, DECIMAL) : undefined,
      );
      form.setValue(CELL_PHONE_FIELD, cellPhoneNumber);
      form.setValue(PHONE_FIELD, phoneNumber);
      form.setValue(EMAIL_FIELD, email);
      form.setValue(EMAIL_CONFIRMATION_FIELD, email);
      form.setValue(GENDER_FIELD, gender);
      form.setValue(ACCEPT_FIELD, true);

      // Update form state to force render update
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ form });
    }
  }

  validateBirthday() {
    const { form } = this.state;
    const birthday = `${form.getValue(MONTH_BIRTHDAY_FIELD)}/${form.getValue(
      DAY_BIRTHDAY_FIELD,
    )}/${form.getValue(YEAR_BIRTHDAY_FIELD)}`;
    const birthdayDate = new Date(birthday);
    birthdayDate.setHours(0, 0, 0, 0);
    return this.adultDate - birthdayDate >= 0;
  }
  validDateWithFields() {
    const { form } = this.state;
    return (
      form.getValue(DAY_BIRTHDAY_FIELD) &&
      form.getValue(MONTH_BIRTHDAY_FIELD) &&
      form.getValue(YEAR_BIRTHDAY_FIELD) &&
      this.validateBirthday()
    );
  }

  formatBirthDay(day, month, year) {
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');
    return `${formattedDay}-${formattedMonth}-${year}`;
  }
  onSubmit() {
    const { form } = this.state;
    const {
      session,
      mixpanelTracker,
      widgetProps,
      config: { tokenName },
    } = this.props;
    const { cardType } = widgetProps;
    const { pub } = window.reservamosPubSub;
    const { profile: profileEventName } = getPubSubEventName(cardType);

    if (!this.validDateWithFields()) return;
    const mobile = form.getValue(CELL_PHONE_FIELD);
    const phone = form.getValue(PHONE_FIELD);
    if (!mobile && !phone) {
      this.setState({
        phoneInvalid: true,
      });
      return;
    }
    this.setState({
      phoneInvalid: false,
    });

    const birthday = this.formatBirthDay(
      form.getValue(DAY_BIRTHDAY_FIELD),
      form.getValue(MONTH_BIRTHDAY_FIELD),
      form.getValue(YEAR_BIRTHDAY_FIELD),
    );

    const newUserInfo = {
      firstName: form.getValue(FIRST_NAME_FIELD),
      lastName: form.getValue(LAST_NAME_FIELD),
      email: form.getValue(EMAIL_FIELD),
      birthday,
      gender: form.getValue(GENDER_FIELD),
      emailConfirmation: form.getValue(EMAIL_CONFIRMATION_FIELD),
      ...(form.getValue(CELL_PHONE_FIELD) && {
        mobile: form.getValue(CELL_PHONE_FIELD),
      }),
      ...(form.getValue(PHONE_FIELD) && { phone: form.getValue(PHONE_FIELD) }),
      ...(form.getValue(ID_FIELD) && { id: form.getValue(ID_FIELD) }),
      ...(form.getValue(PROVIDER_FIELD) && {
        provider: form.getValue(PROVIDER_FIELD),
      }),
    };

    form.updateValidators({
      [EMAIL_CONFIRMATION_FIELD]: {
        validators: [Validators.isEqual(this.validateEmailConfirmation)],
      },
    });

    this.setState({ isLoading: true, error: '' });
    mixpanelTracker.trackSignUpClick(null);

    session
      .signUp(newUserInfo)
      .then(user => {
        this.setState({ error: '' });
        mixpanelTracker.trackSignUp({
          [`${widgetProps.mixpanelPrefix} Card`]: user.sp_card,
          Email: newUserInfo.email,
          Name: `${newUserInfo.firstName} ${newUserInfo.lastName}`,
          Type: `${widgetProps.mixpanelPrefix} Card`,
        });

        const domain = psl.parse(window.location.hostname).domain;
        Cookies.set(tokenName, user.token, {
          domain,
          expires: widgetProps.cookieExpiration,
        });

        pub(profileEventName, {
          user,
          loyaltyName: cardType,
          loading: false,
          error: false,
        });
        this.setState({ isLoading: false });
      })
      .catch(({ response }) => {
        this.setState({ isLoading: false });
        response
          .json()
          .then(({ errors: [error] }) => {
            pub(profileEventName, {
              user: null,
              loyaltyName: cardType,
              loading: false,
              error,
            });
            this.setState({ error: error.message });
          })
          .catch(error => {
            pub(profileEventName, {
              user: null,
              loyaltyName: cardType,
              loading: false,
              error,
            });
            this.setState({ error: 'unexpected_error' });
          });
      })
      .finally(() => {
        history.replaceState(
          {},
          null,
          removeAllURLParams(window.location.href),
        );
      });
  }

  validateEmailConfirmation(value) {
    const { form } = this.state;
    return form && form.getValue(EMAIL_FIELD) === value;
  }

  yearsOptions = () => {
    const minYear = new Date().getFullYear() - 100;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { placeholder_year } = useText({
      placeholder_year: 'placeholder.year',
    });
    const options = [];
    options.push(<option key={0}>{placeholder_year}</option>);

    for (let i = this.adultDate.getFullYear(); i >= minYear; i--) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return options;
  };

  monthsOptions = () => {
    const { form } = this.state;
    const hasToLimit =
      form.getValue(YEAR_BIRTHDAY_FIELD) == this.adultDate.getFullYear();
    const max = hasToLimit ? this.adultDate.getMonth() + 1 : 12;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { placeholder_month } = useText({
      placeholder_month: 'placeholder.month',
    });
    const options = [];

    options.push(<option key={0}>{placeholder_month}</option>);
    for (let i = 1; i <= max; i++) {
      options.push(
        <option key={i} value={i}>
          {this.getMonth(i)}
        </option>,
      );
    }
    return options;
  };

  daysOptions = () => {
    const { form } = this.state;
    const monthSelected = parseInt(
      form.getValue(MONTH_BIRTHDAY_FIELD),
      DECIMAL,
    );

    const hasToLimit =
      form.getValue(YEAR_BIRTHDAY_FIELD) == this.adultDate.getFullYear() &&
      monthSelected == this.adultDate.getMonth() + 1;

    let max = hasToLimit
      ? this.adultDate.getDate()
      : this.getDaysQuantity(monthSelected);
    if (monthSelected == 2)
      max = this.isLeapYear(
        parseInt(form.getValue(YEAR_BIRTHDAY_FIELD), DECIMAL),
      )
        ? 29
        : 28;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { placeholder_day } = useText({ placeholder_day: 'placeholder.day' });
    const options = [];

    options.push(<option key={0}>{placeholder_day}</option>);
    for (let i = 1; i <= max; i++) {
      options.push(
        <option key={i} value={i}>
          {String(i).padStart(2, '0')}
        </option>,
      );
    }
    return options;
  };

  isLeapYear(year) {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  getDaysQuantity(month) {
    return (
      {
        1: 31,
        2: 28,
        3: 31,
        4: 30,
        5: 31,
        6: 30,
        7: 31,
        8: 31,
        9: 30,
        10: 31,
        11: 30,
        12: 31,
      }[month] ?? 31
    );
  }

  getMonth(month) {
    return {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    }[month];
  }

  render({ onChangeContent, mixpanelTracker, thirdParty, widgetProps }) {
    const { form, error, isLoading } = this.state;
    // Variable that defines if hidden fields for thirdParty signup should be visible
    const showField = !this.props.showOnlyConfirmationFields;
    const {
      privacyUrl,
      termsAndConditionsUrl,
      mixpanelPrefix,
      facebookLogin,
      googleLogin,
      outlookLogin,
    } = widgetProps;

    const firstNameIsInvalid = form.fieldHasErrors(FIRST_NAME_FIELD);
    const lastNameIsInvalid = form.fieldHasErrors(LAST_NAME_FIELD);
    const emailIsInvalid = form.fieldHasErrors(EMAIL_FIELD);
    const dayBirthdateInvalid = form.fieldHasErrors(DAY_BIRTHDAY_FIELD);
    const monthBirthdateInvalid = form.fieldHasErrors(MONTH_BIRTHDAY_FIELD);
    const yearBirthdateInvalid = form.fieldHasErrors(YEAR_BIRTHDAY_FIELD);
    const genderInvalid = form.fieldHasErrors(GENDER_FIELD);
    const cellPhoneInvalid = form.fieldHasErrors(CELL_PHONE_FIELD);
    const phoneInvalid = form.fieldHasErrors(PHONE_FIELD);
    const emailConfirmationIsInvalid = form.fieldHasErrors(
      EMAIL_CONFIRMATION_FIELD,
    );
    const acceptIsInvalid = form.fieldHasErrors(ACCEPT_FIELD);

    const stop = e => e.stopPropagation();

    const signInWithCallback = type =>
      mixpanelTracker.trackSignUp({
        [`${mixpanelPrefix} Card`]: null,
        Email: null,
        Name: null,
        Type: type,
      });

    const showLinks = Boolean(
      (privacyUrl || termsAndConditionsUrl) && showField,
    );
    const {
      placeholder_name,
      placeholder_surnames,
      placeholder_email,
      placeholder_confirm_email,
      placeholder_cell_phone,
      placeholder_phone,
      label_gender,
      label_birthdate_date,
      label_male,
      label_female,
      label_other,
      label_do_not_specify,
      label_select_an_option,
      cell_or_phone,
      name_and_lastname,
      no_phone_typed,
      read_terms,
      the,
      them,
      and,
      privacy_notice,
      terms_and_conditions,

      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useText({
      placeholder_name: 'placeholder.name',
      placeholder_surnames: 'placeholder.surnames',
      placeholder_day: 'placeholder.day',
      placeholder_month: 'placeholder.month',
      placeholder_year: 'placeholder.year',
      placeholder_email: 'placeholder.email',
      placeholder_confirm_email: 'placeholder.confirm_email',
      placeholder_cell_phone: 'placeholder.cell_phone',
      placeholder_phone: 'placeholder.phone',
      label_gender: 'label.gender',
      label_birthdate_date: 'label.birthdate_date',
      label_male: 'label.male',
      label_female: 'label.female',
      label_other: 'label.other',
      label_do_not_specify: 'label.do_not_specify',
      label_select_an_option: 'label.select_an_option',
      cell_or_phone: 'label.cell_or_phone',
      name_and_lastname: 'label.name_and_lastname',
      no_phone_typed: 'error.no_phone_typed',
      read_terms: 'terms_and_conditions.read_and_accept',
      the: 'terms_and_conditions.the',
      them: 'terms_and_conditions.the_plural',
      and: 'terms_and_conditions.and',
      privacy_notice: 'terms_and_conditions.privacy_notice',
      terms_and_conditions: 'terms_and_conditions.terms_and_conditions',
    });

    const dateFill =
      !dayBirthdateInvalid &&
      form.getValue(DAY_BIRTHDAY_FIELD) &&
      !monthBirthdateInvalid &&
      form.getValue(MONTH_BIRTHDAY_FIELD) &&
      !yearBirthdateInvalid &&
      form.getValue(YEAR_BIRTHDAY_FIELD);

    const validBirthday = dateFill
      ? this.validateBirthday() && !yearBirthdateInvalid
      : false;
    const dayValid = dateFill ? !validBirthday : dayBirthdateInvalid;
    const monthValid = dateFill ? !validBirthday : monthBirthdateInvalid;
    const yearValid = dateFill ? !validBirthday : yearBirthdateInvalid;

    return (
      <div>
        <form onSubmit={form.onSubmit(this, this.onSubmit)} noValidate>
          <AlertMessage type="error" visible={Boolean(error)}>
            {error}
          </AlertMessage>
          {showField ? (
            <div>
              <label className="form-item-label">{name_and_lastname} *</label>
              <div className="form-item-wrap">
                <FieldWrapper
                  hasError={firstNameIsInvalid}
                  className="form-item-wrap-left"
                  errorLegend="invalid_name"
                >
                  <input
                    name={FIRST_NAME_FIELD}
                    className="invalid"
                    type="text"
                    placeholder={`${placeholder_name}`}
                    onInput={validateField(this, form, null, normalizeName)}
                  />
                </FieldWrapper>
                <FieldWrapper
                  hasError={lastNameIsInvalid}
                  className="form-item-wrap-right"
                  errorLegend="invalid_last_name"
                >
                  <input
                    name={LAST_NAME_FIELD}
                    className="invalid"
                    type="text"
                    placeholder={`${placeholder_surnames} *`}
                    onInput={validateField(this, form, null, normalizeName)}
                  />
                </FieldWrapper>
              </div>
            </div>
          ) : null}

          <label className="form-item-label">{label_birthdate_date} *</label>

          <div className="form-item-wrap">
            <FieldWrapper
              hasError={dayValid}
              className="form-item-wrap-left"
              errorLegend="invalid_day"
            >
              <select
                name={DAY_BIRTHDAY_FIELD}
                onChange={validateField(this, form)}
                value={form.getValue(DAY_BIRTHDAY_FIELD)}
              >
                {this.daysOptions()}
              </select>
            </FieldWrapper>
            <FieldWrapper
              hasError={monthValid}
              className="form-item-wrap-right"
              errorLegend="invalid_month"
            >
              <select
                name={MONTH_BIRTHDAY_FIELD}
                className="invalid"
                onChange={validateField(this, form)}
                value={form.getValue(MONTH_BIRTHDAY_FIELD)}
              >
                {this.monthsOptions()}
              </select>
            </FieldWrapper>
            <FieldWrapper
              hasError={yearValid}
              className="form-item-wrap-right"
              errorLegend="invalid_year"
            >
              <select
                name={YEAR_BIRTHDAY_FIELD}
                className="invalid"
                onChange={validateField(this, form)}
                value={form.getValue(YEAR_BIRTHDAY_FIELD)}
              >
                {this.yearsOptions()}
              </select>
            </FieldWrapper>
          </div>
          <label className="form-item-label">{label_gender} *</label>
          <FieldWrapper
            hasError={genderInvalid}
            className="form-item"
            errorLegend="invalid_gender"
          >
            <select name={GENDER_FIELD} onChange={validateField(this, form)}>
              <option selected={!form.getValue(GENDER_FIELD)}>
                {label_select_an_option}
              </option>
              <option value="f" selected={form.getValue(GENDER_FIELD) === 'f'}>
                {label_female}
              </option>
              <option value="m" selected={form.getValue(GENDER_FIELD) === 'm'}>
                {label_male}
              </option>
              <option value="o" selected={form.getValue(GENDER_FIELD) === 'o'}>
                {label_other}
              </option>
              <option
                value="na"
                selected={form.getValue(GENDER_FIELD) === 'na'}
              >
                {label_do_not_specify}
              </option>
            </select>
          </FieldWrapper>

          <label className="form-item-label">{cell_or_phone} *</label>
          <div className="form-item-wrap">
            <FieldWrapper
              hasError={cellPhoneInvalid || phoneInvalid}
              className="form-item-wrap-left"
              errorLegend=""
            >
              <input
                name={CELL_PHONE_FIELD}
                className="invalid"
                type="tel"
                placeholder={`${placeholder_cell_phone}`}
                onInput={validateField(this, form, null, normalizePhone)}
                value={form.getValue(CELL_PHONE_FIELD)}
              />
              {(cellPhoneInvalid || phoneInvalid) && (
                <p className="error-feedback">{no_phone_typed}</p>
              )}
            </FieldWrapper>
            <FieldWrapper
              hasError={phoneInvalid || cellPhoneInvalid}
              className="form-item-wrap-right"
              errorLegend=""
            >
              <input
                name={PHONE_FIELD}
                className="invalid"
                type="tel"
                placeholder={placeholder_phone}
                onInput={validateField(this, form, null, normalizePhone)}
                value={form.getValue(PHONE_FIELD)}
              />
            </FieldWrapper>
          </div>

          {showField ? (
            <div>
              <label className="form-item-label">{placeholder_email} *</label>
              <FieldWrapper
                hasError={emailIsInvalid}
                className="form-item"
                errorLegend="invalid_email"
              >
                <input
                  name={EMAIL_FIELD}
                  className="invalid"
                  type="email"
                  placeholder={`${placeholder_email} *`}
                  onInput={validateField(this, form)}
                />
              </FieldWrapper>
              <label className="form-item-label">
                {placeholder_confirm_email} *
              </label>
              <FieldWrapper
                hasError={emailConfirmationIsInvalid}
                className="form-item"
                errorLegend="email_does_not_match"
              >
                <input
                  name={EMAIL_CONFIRMATION_FIELD}
                  className="invalid"
                  type="email"
                  placeholder={`${placeholder_confirm_email} *`}
                  onInput={validateField(this, form)}
                />
              </FieldWrapper>
            </div>
          ) : null}
          {showLinks && (
            <FieldWrapper
              hasError={acceptIsInvalid}
              className="form-item"
              errorLegend="accept_terms_and_conditions"
            >
              <label className="form-inline-checkbox">
                <div className="checkboxes-check">
                  <input
                    type="checkbox"
                    name={ACCEPT_FIELD}
                    value="ok"
                    onInput={validateField(this, form)}
                  />
                  <span />
                </div>
                <p className="w-modal-copy">
                  {`${read_terms} `}
                  {Boolean(privacyUrl) && [
                    the,
                    // eslint-disable-next-line react/jsx-key
                    <a
                      href={privacyUrl}
                      onClick={stop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{privacy_notice}</span>
                    </a>,
                  ]}
                  {Boolean(termsAndConditionsUrl) && [
                    Boolean(privacyUrl) && `${and} `,
                    them,
                    // eslint-disable-next-line react/jsx-key
                    <a
                      href={termsAndConditionsUrl}
                      onClick={stop}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{terms_and_conditions}</span>
                    </a>,
                  ]}
                </p>
              </label>
            </FieldWrapper>
          )}
          <div className="form-item">
            <ButtonWrapper
              className="w-modal-button"
              type="submit"
              isLoading={isLoading}
            >
              <Text id={'button.sign_up'} />
            </ButtonWrapper>
          </div>
        </form>
        {thirdParty && showField ? (
          <SignInWith
            facebookLogin={facebookLogin}
            googleLogin={googleLogin}
            outlookLogin={outlookLogin}
            onSelect={signInWithCallback}
          />
        ) : null}
        <div>
          <p className="w-modal-copy account-link">
            {/* eslint-disable-next-line */}
            <a
              className="w-modal-link"
              onClick={() => onChangeContent(modalContent.SIGN_IN)}
            >
              <Text id="label.do_you_have_an_account" />
              <span>
                <Text id="button.log_in" />
              </span>
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default withConfig(SignUpContent);
