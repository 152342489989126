import { h, Component } from 'preact';
import SignInLink from './SignInLink';
import SignInButton from './SignInButton';
import SignInModal from './SignInModal';
import ProfileModal from './ProfileModal';
import SignInLinkHome from './SignInLinkHome';
import Banner from './Banner';
import modalContent from '../constants/modalContent';
import { removeAllURLParams } from '../utils/urlsCleaner';
import '../stylesheets/main.scss';
import {
  initGoogleRecaptcha,
  cleanGoogleRecaptcha,
} from '../utils/googleRecaptcha';
import withConfig from './hoc/withContext';

class SignIn extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
      firstLoadingFinished: false,
    };

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const {
      config: { reCaptchaKey },
      widgetProps,
    } = this.props;
    const { captchaEnabled } = widgetProps;
    if (captchaEnabled && reCaptchaKey) {
      initGoogleRecaptcha({ reCaptchaKey });
    }
  }

  componentWillUnmount() {
    cleanGoogleRecaptcha();
  }

  componentDidUpdate(prevProps) {
    const {
      isLoading: prevIsLoading,
      showModalExtraData: prevShowModalExtraData,
    } = prevProps;
    const { isLoading, insistLogin, showModalExtraData } = this.props;
    const { firstLoadingFinished } = this.state;
    // When component updates its props make sure update happens just one time comparing props and prevProps
    if (!(showModalExtraData === prevShowModalExtraData)) {
      // If prop showModalExtraData means we came just after attempting thirdParty signin
      if (showModalExtraData) {
        this.showModal();
      }
    } else {
      if (firstLoadingFinished || !insistLogin) return;
      if (isLoading === prevIsLoading) return;

      // eslint-disable-next-line react/no-did-update-set-state
      if (!isLoading) this.setState({ firstLoadingFinished: true });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showModal: true });
    }
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    const { clearExtraDataModal, showModalExtraData } = this.props;
    history.replaceState({}, null, removeAllURLParams(window.location.href));
    if (showModalExtraData) clearExtraDataModal();
    this.setState({ showModal: false });
  }

  renderSignInVariant() {
    const {
      buttonText,
      linkText,
      ctaText,
      invitationText,
      onSidebar,
      dotersGradientBanner,
      earnedPoints,
      isLoading,
      modifierClass,
      variant,
      iconType,
      buttonLogo,
      hideIcon,
      insideIcon,
      widgetProps,
    } = this.props;
    const variantProps = { isLoading, onClick: this.showModal };
    const { cardType } = widgetProps;

    switch (variant) {
      case 'link':
        return (
          <div>
            <SignInLink
              {...variantProps}
              modifierClass={modifierClass}
              iconType={iconType}
              hideIcon={hideIcon}
              buttonLogo={buttonLogo}
            />
          </div>
        );

      case 'button':
        return (
          <SignInButton
            {...variantProps}
            buttonText={buttonText}
            invitationText={invitationText}
            iconType={iconType}
            hideIcon={hideIcon}
            insideIcon={insideIcon}
          />
        );

      case 'banner':
        return (
          <Banner
            {...variantProps}
            buttonText={buttonText}
            invitationText={invitationText}
            onSidebar={onSidebar}
            hasGradient={dotersGradientBanner}
            earnedPoints={earnedPoints}
          />
        );

      case 'link-home':
        return (
          <SignInLinkHome
            {...variantProps}
            iconType={iconType}
            hideIcon={hideIcon}
            linkText={linkText}
            cardType={cardType}
            ctaText={ctaText}
          />
        );

      default:
        return null;
    }
  }

  render({
    modalDescription,
    modalIcon,
    session,
    mixpanelTracker,
    thirdParty,
    modalLogo,
    headerColor,
    loggedIn,
    facebookLogin,
    googleLogin,
    outlookLogin,
    widgetProps,
  }) {
    const { showModal } = this.state;
    const { cardType } = widgetProps;
    const { showModalExtraData, user: userInfo } = this.props;

    const modalProps = {
      onClose: this.closeModal,
      session,
      thirdParty,
      loggedIn,
      modalLogo,
      headerColor,
      widgetProps,
      modalIcon,
      modalDescription,
      facebookLogin,
      googleLogin,
      outlookLogin,
      mixpanelTracker,
      ...(showModalExtraData && { modalContent: modalContent.EXTRA_DATA }),
      ...(showModalExtraData && { user: userInfo }),
    };

    const modalTypes = {
      doters: <ProfileModal {...modalProps} showModal={showModal} />,
      default: showModal ? <SignInModal {...modalProps} /> : null,
    };

    return (
      <div>
        {this.renderSignInVariant()}

        {modalTypes[cardType] ?? modalTypes['default']}
      </div>
    );
  }
}

export default withConfig(SignIn);
