import { h } from 'preact';
import { useText, Text as Texti18 } from 'preact-i18n';

const DotersHeader = ({ user, onShowModal }) => {
  const { hello } = useText({
    hello: 'label.hello',
  });

  if (!user) return null;
  const { available_points, level, first_name } = user;
  const levelToShow = level.toLowerCase();

  const levelClassMapping = {
    gold: 'premium',
    silver: 'elite',
    green: 'basic',
  };

  const levelClass = levelClassMapping[levelToShow] || '';

  return (
    <div className={`doters-header box-${levelClass}`} onClick={onShowModal}>
      <div className="doters-header-flex" alignItems="center" size="XS">
        <i className={`doters-${levelClass}`} />
        <p
          className="doters-header-text hide-for-desktop"
          size="S"
          weight="semibold"
          color="white"
        >
          {hello}, {first_name}
        </p>
      </div>
      <p
        className="doters-header-text"
        size="S"
        weight="semibold"
        color="white"
      >
        <Texti18
          id="label.you_have_doters_points"
          fields={{
            count: available_points
              ?.toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          }}
        />
      </p>
      <div className="doters-header-border" />
    </div>
  );
};

export default DotersHeader;
