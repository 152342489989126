const mixpanelPrefix = brand => {
  switch (brand) {
    case 'GFA':
      return 'Siempre Plus';

    case 'IAMSA':
      return 'Loyalty Program';
    
    case 'costapass':
      return 'Costapass Loyalty'

    default:
      return 'Loyalty Program';
  }
};

export default mixpanelPrefix;
