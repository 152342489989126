const copies = {
  production: {
    doters: {
      links: {
        myProfile: 'https://www.doters.com/profile',
        theProgram: 'https://www.doters.com/programa',
        winAndUse: 'https://www.doters.com/ganar-y-usar',
        levelsAndBenefits: 'https://www.doters.com/niveles-y-beneficios',
        frequentlyQuestions: 'https://www.doters.com/faq',
      },
    },
  },
  development: {
    doters: {
      links: {
        myProfile: 'https://staging.doters.umvel.dev/profile',
        theProgram: 'https://staging.doters.umvel.dev/programa',
        winAndUse: 'https://staging.doters.umvel.dev/ganar-y-usar',
        levelsAndBenefits:
          'https://staging.doters.umvel.dev/niveles-y-beneficios',
        frequentlyQuestions: 'https://staging.doters.umvel.dev/faq',
      },
    },
  },
};

export default copies;
