import { h } from 'preact';
import { Text } from 'preact-i18n';
import { useContext } from 'preact/hooks';
import capitalize from '../utils/capitalize';
import ConfigContext from './context/ConfigContext';

function SignInWithItem({ name, href = '', onSelect }) {
  const onClick = () => onSelect(capitalize(name));

  return (
    <a className="social-button-item" href={href} onClick={onClick}>
      <i className={`social-button-${name}`} />
      <p className="social-button-label">
        <Text id="button.log_in" />
        <span>
          {' '}
          {name}
        </span>
      </p>
    </a>
  );
}

export default function SignInWith({ onSelect, facebookLogin, googleLogin, outlookLogin }) {
  const config = useContext(ConfigContext);
  const urlEncode = encodeURIComponent(window.location.href);
  return (
    <div>
      {(facebookLogin || googleLogin || outlookLogin) && 
        <div className="or">
          <hr className="bar" />
          <span>O</span>
          <hr className="bar" />
        </div>
      }
      <div className="social-button">
        {facebookLogin && <SignInWithItem
          onSelect={onSelect}
          name="facebook"
          href={`${config.root}/auth/facebook?origin=${urlEncode}`}
        />}
        {googleLogin && <SignInWithItem
          onSelect={onSelect}
          name="google"
          href={`${config.root}/auth/google_oauth2?origin=${urlEncode}`}
        />}
        {outlookLogin && <SignInWithItem
          onSelect={onSelect}
          name="outlook"
          href={`${config.root}/auth/microsoft_v2_auth?origin=${urlEncode}`}
        />}
      </div>
    </div>
  );
}
