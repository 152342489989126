import { h } from 'preact';
import { Text } from 'preact-i18n';

const CostapassUserInfo = ({ user, signOut, modifierClass}) => (
  <div className={`user-info ${modifierClass}`}>
    <p className="user-info-title">
      <i className="badge-costapass" />
      <span>
        <Text id="text.hello" />
      </span>
      <b>{user.name}</b>
    </p>
    <div className="tooltip">
      <div className="user-nav">
        {/* aquí irá opción Ver perfil completo */}
        <div className="user-nav-row">
          <a className="user-nav-item" onClick={signOut}>
            <Text id="link.sign_off" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default CostapassUserInfo;