import { h } from 'preact';
import LoadingDots from './LoadingDots';
import { useText } from 'preact-i18n';

const Banner = ({
  invitationText,
  buttonText,
  isLoading,
  onClick,
  onSidebar,
  hasGradient,
  earnedPoints,
}) => {
  const { doters_points, with_this_purchase_you_earn } = useText({
    with_this_purchase_you_earn: 'label.with_this_purchase_you_earn',
    doters_points: 'label.doters_points',
  });

  const bannerClassName = ['banner-signin'];
  if (onSidebar) {
    bannerClassName.push('on-sidebar');
  }
  if (hasGradient) {
    bannerClassName.push('banner-gradient');
  }

  const iconClassName = earnedPoints ? 'doters-levels' : 'doters-basic';

  return (
    <div
      className={bannerClassName.join(' ')}
      onClick={onClick}
      title={buttonText}
    >
      <div className={`banner-signin-pattern`} />
      <div className={`banner-signin-wrapper`}>
        <div className="banner-signin-message">
          <i className={iconClassName} />
          {earnedPoints ? (
            <div>
              <p className="banner-signin-label-small">
                {with_this_purchase_you_earn}
              </p>
              <p className="banner-signin-label">
                {`${earnedPoints} ${doters_points}`}
              </p>
            </div>
          ) : (
            <span className="banner-signin-label">{invitationText}</span>
          )}
        </div>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <button
            type="button"
            className="banner-signin-button"
            onClick={onClick}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default Banner;
