import { h } from 'preact';
import { Text } from 'preact-i18n';
import modalContent from '../constants/modalContent';
import EmailIcon from './icons/EmailIcon';

const EmailSentContent = ({ onChangeContent }) => {
  return (
    <div className="w-modal-email">
      <EmailIcon />
      <p className="w-modal-copy">
        <Text id="text.link_sent_to_reset_password" />
      </p>
      <div>
        <button
          type="button"
          className="w-modal-button"
          onClick={() => onChangeContent(modalContent.SIGN_IN)}
        >
          <Text id="button.log_in" />
        </button>
      </div>
    </div>
  );
};

export default EmailSentContent;
