export default function mixpanelTracker(variant, brandPrefix) {
  return {
    /**
     * track mixpanel event
     * @param {String} event
     * @param {Object?} properties
     */
    track(event, properties) {
      const allProperties = {
        ...properties,
        Variant: variant,
      };

      return new Promise(resolve => {
        if (window.mixpanel) {
          window.mixpanel.track(event, allProperties, resolve);
        } else {
          console.warn(event, allProperties);
          resolve(false);
        }
      });
    },

    /**
     * track for Loyalty Program Sign In Click
     * @param { String } card
     */
    trackSignInClick(card) {
      return this.track(`${brandPrefix} Sign In Click`, {
        [`${brandPrefix} Card`]: card,
      });
    },

    /**
     * track for Loyalty Program Sign Up Click
     * @param { String } card
     */
    trackSignUpClick(card) {
      return this.track(`${brandPrefix} Sign Up Click`, {
        [`${brandPrefix} Card`]: card,
      });
    },

    /**
     * track for Loyalty Program Sign In
     * @param {{ Type: String, 'Loyalty Program Card': String }} properties
     */
    trackSignIn(properties) {
      return this.track(`${brandPrefix} Sign In`, properties);
    },

    /**
     * track for Loyalty Program Forgot Password
     * @param {String} email
     */
    trackForgotPassword(email) {
      return this.track(`${brandPrefix} Forgot Password`, { Email: email });
    },

    /**
     * track for Loyalty Program Sign Up
     * @param {{
     *   'Type': String,
     *   'Name': String,
     *   'Email': String,
     *   'Loyalty Program Card': String
     * }} properties
     */
    trackSignUp(properties) {
      return this.track(`${brandPrefix} Sign Up`, properties);
    },

    /**
     * track for Loyalty Program Sign Out
     * @param {string} card
     */
    trackSignOut(card) {
      return this.track(`${brandPrefix} Sign Out`, {
        [`${brandPrefix} Card`]: card,
      });
    },

    identifyUser(userId, traits, options, callback) {
      window.mixpanel.identify(userId);
      window.mixpanel.people.set(traits, callback);
    },

    getDistinctId() {
      /* eslint-disable-next-line no-underscore-dangle */
      if (window.mixpanel && window.mixpanel.__loaded) {
        return window.mixpanel.get_distinct_id();
      }

      console.warn('Analytics ➞ User Distinct Id not available');
      return '';
    },
  };
}
