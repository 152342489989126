import { h, Component } from 'preact';

export default class Modal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render({ title = 'modal_title', modalIcon, modalLogo, description, headerColor, onUserClose, isLoading }) {
    const { children } = this.props;
    const closeIcon = headerColor ? (
      <i onClick={onUserClose} role="none" className="w-modal-close-white" />
    ):(
      <i onClick={onUserClose} role="none" className="w-modal-close" />
    )

    return (
      <div className="w-modal">
        <div className="w-modal-container">
          <div className={`w-modal-title-container ${headerColor ? 'has-bg' : ''}`} >
            <div className="w-modal-title-container-wrapper">
              {modalIcon && <i className={`badge-${modalIcon}`} />}
              {modalLogo ? <i className="doters-logo-widget" /> : <h3 className="w-modal-title">{title}</h3>}
            </div>
            {!isLoading && closeIcon}
          </div>
          <div className="w-modal-content">
            {description && <p className="w-modal-description">{description}</p>}
            {children}
          </div>
        </div>
      </div>
    );
  }
}
