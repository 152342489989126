export default class ValidatorIsEqual {
  constructor (evaluator) {
    this.errorName = 'evaluate';
    this.evaluator = evaluator;
  }

  isValid (value) {
    return this.evaluator(value);
  }
}
