/**
 * @typedef HideConfig
 * @property {Boolean} withoutSession
 * @property {Boolean} withSession
 * @property {Boolean} onLoading
 */

/**
 * Parsea la propiedad hidden y genera un objeto con la configuración
 * @param {String} attribute
 * @param {String?} separator
 * @returns {HideConfig}
 */
export default function parseHiddenProp(attribute, separator = ' ') {
  /* eslint-disable no-param-reassign */
  return attribute
    .split(separator)
    .reduce((attributeValues, attr) => {
      attributeValues[attr] = true;
      return attributeValues;
    }, {});
}
