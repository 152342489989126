import { h } from 'preact';
import { Text } from 'preact-i18n';
import LoadingPulse from './LoadingPulse';

const SignInLink = ({ onClick, modifierClass, isLoading, iconType, hideIcon, buttonLogo }) => (isLoading ? (
  <LoadingPulse modifierClass={modifierClass} />
) : (
  <div className={`signin ${modifierClass}`}>
    <button className="signin-button" type="button" onClick={onClick} aria-label="signin-button">
      {buttonLogo ? (
        <i className={`signin-logo-${buttonLogo}`} />
      ) :
      (
        <div>
        {hideIcon || <i className={`badge-${iconType}`} />}
      <span className="signin-label"><Text id="button.log_in" /></span>
        </div>
      )}
    </button>
  </div>
));

export default SignInLink;
