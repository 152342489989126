import { h } from 'preact';
import apiConfig from '../../../config/apiConfig';

export default function withConfig(WrappedComponent) {
  return function ConfigWrapper(props) {
    const { widgetProps } = props;
    const { origin, cardType, cookieExpiration, endpointName } = widgetProps;
    const config = {
      ...apiConfig(cardType, origin, cookieExpiration, endpointName),
    };
    return <WrappedComponent {...props} config={config} />;
  };
}
