import { h } from 'preact';
import { Text } from 'preact-i18n';

const SiemprePlusProfileModal = ({ user, cardLabel, kmsLabel, isRegistered, signOut, modifierClass}) => (
  <div className={`user-info ${modifierClass}`}>
    <p className="user-info-title">
      <i className="user-info-icon" />
      <span>
        <Text id="text.hello" />
      </span>
      <b>{user.first_name}</b>
    </p>
    <div className="tooltip">
      <div className="user-nav">
        {isRegistered && (
          <div className="user-nav-row">
            <span className="user-nav-item">{user.email}</span>
          </div>
        )}
        <div className="user-nav-row">
          {/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          <a className="user-nav-item">
            <Text id={`label.${cardLabel}`} />:
            <span className="user-nav-data">{user.sp_card}</span>
          </a>
        </div>
        <div className="user-nav-row">
          <a className="user-nav-item">
            <Text id={`label.${kmsLabel}`} />:
            <span className="user-nav-data">{user.available_kms}</span>
          </a>
        </div>
        {/* aquí irá opción Ver perfil completo */}
        <div className="user-nav-row">
          <a className="user-nav-item" onClick={signOut}>
            <Text id="link.sign_off" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default SiemprePlusProfileModal;