import { h } from 'preact';
import LoadingDots from '../LoadingDots';

/* eslint-disable react/button-has-type */
const ButtonWrapper = ({
  isLoading = false,
  disabled = false,
  type = 'button',
  children,
  className = '',
  onClick,
}) => (
  <button
    type={type}
    disabled={isLoading || disabled}
    className={className}
    onClick={onClick}
  >
    {/* eslint-enable react/button-has-type */}
    {isLoading ? <LoadingDots /> : children}
  </button>
);

export default ButtonWrapper;
