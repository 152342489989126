import { h, Component } from 'preact';
import { Text, useText } from 'preact-i18n';
import { Form, Validators, validateField } from '../utils/form-helpers';
import modalContent from '../constants/modalContent';
import FieldWrapper from './generics/FieldWrapper';
import ButtonWrapper from './generics/ButtonWrapper';
import AlertMessage from './generics/AlertMessage';
import emitEvent from '../utils/emitEvent';

const field = {
  EMAIL: 'email',
};

class ForgotPinContent extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      isLoading: false,
      error: '',
      form: new Form({
        [field.EMAIL]: {
          value: '',
          validators: [Validators.required(), Validators.email()],
        },
      }),
    };
  }

  componentDidMount() {
    const { form } = this.state;
    form.clearValues(this);
  }

  onSubmit() {
    const { form } = this.state;
    const { onChangeContent, session, mixpanelTracker } = this.props;
    const email = form.getValue(field.EMAIL).toLowerCase();
    this.setState({ isLoading: true, error: '' });

    session
      .forgotPin(email)
      .then(() => {
        onChangeContent(modalContent.EMAIL_SENT);
        this.setState({ isLoading: false });
        emitEvent('forgotPin', null);
      })
      .catch(({ response }) => {
        response
        .json()
        .then(({ errors: [error] }) => {
          this.setState({
            isLoading: false,
            error: error.message,
          });
          emitEvent('forgotPin', error.message);
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            error: 'unexpected_error',
          });
          emitEvent('forgotPin', error);
        });
      })
      .finally(() => {
        mixpanelTracker.trackForgotPassword(email);
      });
  }

  render() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { placeholder_email } = useText('placeholder.email');
    const { form, isLoading, error } = this.state;
    const emailIsInvalid = form.fieldHasErrors(field.EMAIL);
    return (
      <form onSubmit={form.onSubmit(this, this.onSubmit)} noValidate>
        <AlertMessage visible={Boolean(error)} type="error">
          {error}
        </AlertMessage>
        <FieldWrapper
          hasError={emailIsInvalid}
          className="form-item"
          errorLegend="write_a_valid_email"
          type="notification"
        >
          <input
            type="email"
            name={field.EMAIL}
            placeholder={placeholder_email}
            onInput={validateField(this, form)}
          />
        </FieldWrapper>
        <div>
          <ButtonWrapper
            type="submit"
            className="w-modal-button"
            isLoading={isLoading}
          >
            <Text id={'button.send_mail'} />
          </ButtonWrapper>
        </div>
      </form>
    );
  }
}

export default ForgotPinContent;
